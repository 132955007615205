import {
    GET,
    db
} from '@/firebase.js'

import Avatar from '@/components/Avatar/Avatar.vue'
import Mat from '@/components/Mat/Mat.vue'
import Badge from '@/components/Badge/Badge.vue'
import Instasel from '@/components/Instasel/Instasel.vue'
import RankCard from '@/components/RankCard/RankCard.vue'
import Gad from '@/components/Gad/Gad.vue'

export default {
    name: 'Home',
    components: {
        Avatar,
        Mat,
        Badge,
        Instasel,
        RankCard,
        Gad
    },
    props: {
        event: Object
    },
    data: function () {
        return {
            orgs: [],
            popularBadgesIds: ['Renewable Energizer', 'Equal Management', 'Union Partner'],
            popularBadges: [],
            activeFeatured: 0,
            activeThesis: 0,
            featuredRankings: [],
            hideFeaturedRankingInstasel: false,
            featuredBadgesDragged: false,
            avatarsDragged: false,
            mobile: false,
            featured: [{
                pretitle: 'Rankings',
                title: 'Easily compare equality and impact.',
                img: 'rankings.svg',
                CTA: {
                    label: 'Explore Rankings',
                    link: '/rankings'
                }
            }, {
                pretitle: 'Badges',
                title: 'Explore badges, set your standards.',
                img: 'badges.svg',
                CTA: {
                    label: 'Discover Badges',
                    link: '/badges'
                }
            }, {
                pretitle: 'Companies',
                title: 'Discover companies, meet your match.',
                img: 'companies.svg',
                CTA: {
                    label: 'View Companies',
                    link: '/companies'
                }
            }],
            featuredRankingRids: ['total_eq', 'boa_eq', 'senmang_eq'],
            thesisCompanies: [],
            featuredRanking: {},
            coldProduction: (window.location.hostname === 'gokind.app'),
            desktop: (window.innerWidth > 768)
        }
    },
    methods: {
        carouselPageChange: function () {
            this.hideFeaturedRankingInstasel = true
        },
        featuredSwitcher: function () {
            setInterval(() => {
                this.activeFeatured++
                if (this.activeFeatured >= this.featured.length) this.activeFeatured = 0
            }, 5000)
        },
        getOrgs: function () {
            if (!this.$event.id) return console.log('Event not loaded')
            let col = db.collection('orgs').where('events.' + this.$event.id, '==', true)
            col = col.where('__name__', 'in', this.$event.popularOrgs)
            col = col.limit(6)
            GET(col, true).then(orgs => {
                orgs.forEach(org => {
                    this.orgs.push({
                        name: org.data().name,
                        logo: org.data().logo,
                        oid: org.data().oid,
                        member: org.data().member
                    })
                })
            })
        },
        getThesisCompanies: function () {
            if (!this.$event.id) return console.log('Event not loaded')
            let col = db.collection('orgs').where('events.' + this.$event.id, '==', true)
            col = col.where('member', '==', true)
            col = col.limit(3)
            GET(col).then(orgs => {
                orgs.forEach(org => {
                    this.thesisCompanies.push({
                        name: org.data().name,
                        logo: org.data().logo,
                        oid: org.data().oid,
                        avatar: {
                            src: org.data().logo,
                            type: 'img'
                        }
                    })
                })
            })
        },
        thesisSwitcher: function () {
            setInterval(() => {
                this.activeThesis++
                if (this.activeThesis >= this.thesisCompanies.length) this.activeThesis = 0
            }, 4000)
        },
        getPopularBadges: function () {
            this.popularBadges = []
            let col = db.collection('badges').where('__name__', 'in', this.$event.featuredBadges)
            GET(col).then(badges => {
                badges.forEach(badge => {
                    this.popularBadges.push({
                        icon: badge.data().icon,
                        type: badge.data().type,
                        title: badge.data().title,
                        desc: badge.data().short_desc,
                        id: badge.id
                    })
                })
            })
        },
        getFeaturedRanking: function () {
            GET(this.$event.featuredRanking).then(ranking => {
                this.featuredRanking = {
                    name: ranking.data().name,
                    desc: ranking.data().desc,
                    id: ranking.id,
                    rid: ranking.data().rid,
                    unit: ranking.data().unit,
                    icon: ranking.data().icon,
                    color: ranking.data().color,
                    type: ranking.data().type,
                    trends: ranking.data().trends
                }
                console.log(this.featuredRanking)
            })
        },
        setMobileStatus: function () {
            this.mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            console.log('Device is mobile: ' + this.mobile)
        },
        viewportListener: function () {
            window.onresize = () => {
                this.desktop = (window.innerWidth > 768)
            };
        }
    },
    created: function () {
        this.getPopularBadges()
        this.featuredSwitcher()
        this.getThesisCompanies()
        this.thesisSwitcher()
        this.getFeaturedRanking()
        this.getOrgs()
        this.setMobileStatus()
        //this.viewportListener()
        this.desktop = false
    }
}