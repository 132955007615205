import {
    db,
    GET
} from '@/firebase.js'

import Avatar from '@/components/Avatar/Avatar.vue'

export default {
    name: 'Badge',
    props: {
        type: String,
        icon: String,
        size: String,
        title: String,
        desc: String,
        id: String,
        disableLink: Boolean
    },
    components: {
        Avatar
    },
    data: function () {
        return {
            orgs: []
        }
    },
    methods: {
        goToBadge: function () {
            if (this.disableLink) return
            this.$router.push({
                path: '/badge/' + this.id + '?event=' + this.$event.eid
            })
        },
        getPromises: function () {
            let col = db.collection('orgs').where('badges', 'array-contains', db.doc('badges/' + this.id))
            col = col.where('events.' + this.$event.id, '==', true)
            col = col.limit(3)
            GET(col, true).then(orgs => {
                orgs.forEach(org => {
                    this.orgs.push({
                        logo: org.data().logo,
                        name: org.data().name,
                        id: org.id,
                        oid: org.data().oid
                    })
                })
            })
        }
    },
    created: function () {
        if (this.size === 'large' || this.size === 'medium') this.getPromises()
    }
}