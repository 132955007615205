export default {
    name: 'Carousel',
    props: {
        num: Number,
        disabled: Boolean,
        theme: {
            type: String,
            default: 'dark'
        },
        duration: {
            type: Number,
            default: 4000
        }
    },
    data: function () {
        return {
            active: 0,
            animationDuration: '4s',
            switcherInterval: null,
        }
    },
    methods: {
        switcher: function () {
            this.switcherInterval = setInterval(() => {
                this.active++
                if (this.active >= this.num) this.active = 0
            }, this.duration)
        },
        setAnimationDuration: function () {
            this.animationDuration = (this.duration / 1000) + 's'
        }
    },
    watch: {
        disabled: function (newVal) { // watch it
            if (newVal === true) clearInterval(this.switcherInterval)
        }
    },
    created: function () {
        this.switcher()
        this.setAnimationDuration()
    }
}